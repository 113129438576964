.flex {
  display: flex;
}
.projection-wrapper {
  background-image: url(../images/bg-rzuty.jpg);
  width: 100%;
  padding: 100px 0;
}
.projection__map.map {
  margin-top: 0px;
}
.p-nav-element--active {
  background-color: $zielony !important;
}
.p-nav__container {
  display: flex;
  justify-content: space-between;
}
.p-nav-parter-left, .p-nav-pietro-left {
  margin-right: 30px;
}
.p-nav__container1 {
  margin-bottom: 30px;
}

.p-nav-element {
  cursor: pointer;
  font-size: 1.7rem;
  background-color: $szary-ciemny;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  transition: all 0.3s;
}
.p-nav-element:hover {
  background-color: #3B3C3F;
  transition: all 0.3s;
}

.projection__data {
  background-color: #57342c;
  padding: 30px 30px;
  color: $font2;
  font-size: 1.7rem;
  &--element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    &-number {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
        height: 30px;
        width: 30px;
      }
    }
  }
  &--element:first-child {
    padding-bottom: 20px;
  }
}

.projection-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projection__data--element-meters {
  white-space: nowrap;
}
@media screen and (max-width: 991px) {
  .projection {
    width: 100%;
  }
  .projection__js {
    margin: 30px 0;
  }
  .projection-right {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-nav-element, .projection__data {
    font-size: 1.5rem;
  }
  .projection__data {
    padding: 20px 20px;
  }
  .projection-right {
    padding-left: 8px;
    padding-right: 8px;
  }
  .projection-wrapper {
    padding: 60px 0;
  }
  .p-nav-parter-left, .p-nav-pietro-left {
    margin-right: 10px;
  }
  .p-nav__container1 {
    margin-bottom: 10px;
  }
}
