.title {
  display: flex;
  align-items: flex-end;
  &__header {
    border-bottom: 2px solid $zielony;
    color: $font2;
    font-size: 2.4rem;
    display: block;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-left: 20px;
    width: 100%;
    text-align: left;
  }
  &__header.main-font {
    color: $font1;
  }
}
.title__image {
  float: left;
}
@media screen and (max-width: 991px) {
  .title {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .title {
    width: 100%;
  }
}
