.header__wrapper {
  width: 100%;
  background-color: $brazowy;
  position: fixed;
  height: 82px;
  z-index: 15;
}

.slider-wrapper {
  width: 100%;
  //background-color: $brazowy;
  padding-top: 82px;
}

.investment-wrapper {
  width: 100%;
  //background-color: $brazowy;
}
