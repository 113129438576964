.investment {
  width: 100%;
  max-width: 1920px;
  background-image: url(../images/bg-inwestycja.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 2.6% 70px 2.6%;
  color: $font2;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: justify;
  margin: 0 auto;
  &__banner {
    text-align: center;
    font-size: 2rem;
    font-family: 2.7rem;
    &--text {
      background-color: $zielony;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__info {
    padding-top: 40px;
    padding-bottom: 40px;
    &--text {
      padding-bottom: 25px;
    }
    &--text:last-of-type {
      padding-bottom: 0;
    }
  }
  // botttom -> grid
  &__element {
    height: 415px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 30px;
    img {
      max-width: 100%;
      height: auto;
    }
    &:hover a.investment__link {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s;
    }
    a.investment__link {
      transition: all 0.3s;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-right: 0;
      border: 10px solid $zielony;
      //transform: translate(-50%, -50%);
      //background-image: url(../images/zdjecia-hover.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.investment__element1 {
  background-image: url(../images/Osiedle-Klonowe-galeria-01-min.jpg);
}
.investment__element2 {
  background-image: url(../images/Osiedle-Klonowe-galeria-02-min.jpg);
}
.investment__element3 {
  background-image: url(../images/Osiedle-Klonowe-galeria-03-min.jpg);
}
.investment__element4 {
  background-image: url(../images/Osiedle-Klonowe-galeria-04-min.jpg);
}
.investment__element5 {
  background-image: url(../images/Osiedle-Klonowe-galeria-05-min.jpg);
}
.investment__element6 {
  background-image: url(../images/Osiedle-Klonowe-galeria-06-min.jpg);
}

.lg-outer .lg-thumb-item {
  border-color: transparent !important;
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: $zielony !important;
}


@media screen and (max-width: 1500px) {
  .investment__element {
    height: 300px;
  }

}

@media screen and (max-width: 1024px) {
  .investment__element a.investment__link {
    visibility: visible;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: none;
    transition: none;
    transform: none;
    margin-right: 0;
    border: none;
  }
  .investment__element {
    height: 215px;
  }
}
@media screen and (max-width: 991px) {
  .width100 {
    width: 100%;
  }
  .investment {
    padding: 50px 13px 30px 13px;
  }
  .investment__padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .investment__element {
    margin-bottom: 20px;
  }
  .investment__banner {
    padding-top: 30px;
  }
  .investment__banner--text {
    height: 76px;
  }
  .investment__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 800px) {
  .investment__element {
    height: 250px;
  }
}
@media screen and (max-width: 767px) {
  .investment__info {

  }
}

@media screen and (max-width: 500px) {
  .investment__info--text:last-of-type {
    padding-bottom: 30px;
  }
  .investment__info {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .investment__info:last-of-type {
    padding:0;
  }
  .investment__banner--text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .investment__banner {
    padding-top: 20px;
    font-size: 1.7rem;
    line-height: 2rem;
  }
  .investment__element {
    height: 220px;
  }
  .investment [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .investment {
    padding: 35px 8px 30px 8px;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 400px) {
  .investment__element {
    height: 260px;
  }
}
@media screen and (max-width: 350px) {
  .investment__element {
    height: 220px;
  }
}
