// colors
$font1: #54555a;
$font2: #fff;
$white: #fff;
$black: #000;
$zielony: #00a671;
$zielony-hover: #009565;
$zielony-menu-hover: #00a671;
$ciemna-zielen: #071f0f;
$brazowy: #60372f;
$brazowy-hover: #56312a;
$ciemny-braz1: #3c201c;
$ciemny-braz2: #2a1b16;
$ciemny-braz3: #2b211d;
$szary-ciemny: #54555a;
$szary-ciemny-hover: #4b4c51;
$szary2: #85878d;
$szary3: #b2b2b2;
$szary4: #e5e5e5;

//header colors
// font
$Foco-Regular: "Foco-Regular", sans-serif;
$Foco-Bold: "Foco-Bold", sans-serif;

$Lato-Regular: "Lato-Regular", sans-serif;
$Lato-Bold: "Lato-Bold", sans-serif;
$Lato-Light: "Lato-Light", sans-serif;

$Proxima-Regular: "Proxima-Regular", sans-serif;
$Proxima-Bold: "Proxima-Bold", sans-serif;

$Panton-LightCaps: "Panton-LightCaps", sans-serif;
$Panton-Light: "Panton-Light", sans-serif;
$Panton-SemiBold: "Panton-SemiBold", sans-serif;
$Panton-Italic: "Panton-Italic", sans-serif;
$Panton-LightItalic: "Panton-LightItalic", sans-serif;
$Panton-Bold: "Panton-Bold", sans-serif;

$Roboto-Bold: "Roboto-Bold", sans-serif;
