.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
#myBtn {
  color: #fff;
  cursor: pointer;
}
/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    position: relative;
}

/* The Close Button */
.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: 6px;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
    font-size: 30px;
    font-family: $Lato-Bold;
    height: 80px;
    background-color: $zielony;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal Body */
.modal-body {
  padding: 20px;
  font-size: 20px;
  font-family: $Lato-Regular;
}
.modal-body p {
  padding-bottom: 10px;
}
.modal-body p:last-child {
  padding-bottom: 0;
}
/* Modal Footer */
.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Modal Content */
.modal-content {
    position: relative;
    top: 50%;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    transform: translateY(-50%);
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top: -300px; opacity: 0; transform: translateY(-50%)}
    to {top: 50%; opacity: 1; transform: translateY(-50%)}
}

@keyframes animatetop {
  from {top: -300px; opacity: 0; transform: translateY(-50%)}
  to {top: 50%; opacity: 1; transform: translateY(-50%)}
}

@media screen and (max-width: 1500px) {
  .modal-header {
    font-size: 25px;
    height: 60px;
  }
  .modal-body p {
    padding-bottom: 8px;
  }
  .modal-body {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 90%;
 }
}
