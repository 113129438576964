.header {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 82px;
  padding: 0 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 15;
  &__logo {

  }
  &__hamburger {
    display: none;
  }
}
.site-h1 {
  text-indent: -100000px;
  font-size: 0.1px;
  height: 0.1px;
  visibility: hidden;
}
.socials__description p {
  color: $zielony;
}
.nav {
  display: flex;
  &__link {
    color: $font2;
    font-size: 1.25rem;
    padding: 0 12px;
    text-transform: uppercase;
    transition: 0.3s;
    font-family: $Lato-Regular;
    &:hover {
      color: $zielony-menu-hover;
      transition: 0.3s;
    }
  }
}

.socials__images a svg {
  max-width: 100%;
  height: auto;
  display: block;
}
.nav__link.active {
  color: $zielony;
}
.socials {
  position: relative;
  &__images {
    display: flex;
    align-items: center;
    a {
      margin: 0 5px;
      display: block;
    }
  }
  &__description {
    color: $font2;
    position: absolute;
    top: 35px;
    display: flex;
    font-size: 1.2rem;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    p {
      display: none;
    }
  }
  &__icon path {
    fill: $white;
    transition: all 0.3s;
  }
  &__icon:hover path {
    fill: $zielony-menu-hover;
    transition: all 0.3s;
  }
}

// XXXXXXXXXXXXXXXXXXXXXXXXXXXX
// HAMBURGER ANIMATION
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXX

@keyframes hamburger {
  0%   {transform: rotate(0);}
  100% {transform: rotate(180deg);}
}

@media screen and (max-width: 1500px) {
  .header {
    padding: 0 1.5%;
  }
  .nav {
    &__link {
      padding: 0 6px;
    }
  }
}


@media screen and (max-width: 1250px) {
  .header {
    padding: 0 1%;
  }
  .nav {
    &__link {
      padding: 0 3px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .nav__link.active {
    color: $brazowy;
  }
  .header {
    padding: 0 23px;
  }
  .header__hamburger {
    display: flex;
    cursor: pointer;
  }
  .header__nav, .header__socials {
    display: none;
  }
  .header.header-active {
    .header__hamburger {
      animation: hamburger;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
    .header__nav  {
      display: block;
      text-align: center;
      position: absolute;
      top: 82px;
      background: $zielony;
      width: 100%;
      left: 0;
      right: 0;
      padding: 20px 0;
    }
    .nav__link {
      display: block;
      font-family: $Lato-Regular;
      font-size: 1.6rem;
      padding:7px 0;
    }
    .header__socials {
      display: flex;
      justify-content: center;
      position: absolute;
      background-color: $zielony;
      width: 100%;
      left: 0;
      right: 0;
      padding-bottom: 15px;
      top: 411px;
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 0 10px;
  }
  .header__logo img {
    width: 217px;
  }
}
