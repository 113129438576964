.scheme {
  font-size: 1.7rem;
  line-height: 2.4rem;
  z-index: 2;
}

.scheme__link {
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  margin-top: 50px;
  color: $font2;
  background-color: $brazowy;
  background-image: url(../images/arrow-button-right.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
  text-transform: uppercase;
  transition: 0.3s all;
}
.scheme__link:hover {
  transition: 0.3s all;
  background-color: $brazowy-hover;
}
.scheme__info--text {
  padding-bottom: 30px;
}
.scheme__info--text:last-of-type {
  padding-bottom: 0px;
}
#standard .park__info--text {
  text-align: left;
}
#okolica .scheme__info--text.localization__info--text {
  text-align: left;
}
.list {
  font-size: 1.7rem;
  line-height: 2.4rem;
  background-color: $zielony;
  color: $white;
  padding: 25px 30px;
  &__element {
    line-height: 3.2rem;
  }
}
.scheme__list--mobile {
  display: none;
}

.after-arrow {
  display: none;
  width: 100%;
  height: 40px;
  background-color: $zielony;
  background-image: url(../images/arrow-button-down.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.after-arrow-active {
  background-image: url(../images/arrow-button-up.svg);
}

@media screen and (max-width: 991px) {
  .after-arrow {
    display: block;
  }
  .scheme__info {
    padding-top: 30px;
  }
  .scheme__list--hide {
    height: 274px;
    overflow: hidden;
    transition: all 0.5s;
  }
  .scheme__list--hide-active {
    height: 662px;
  }
  .scheme__list--hide-active .list {
    background-color: rgba(0, 166, 113, 0.9);
  }
  .scheme__list--hide {
    position: absolute;
    right: 15px;
    z-index: 11;
  }
  .after-arrow {
    position: absolute;
    top: 274px;
    left:0;
    width: calc(100% - 15px);
    right: 0;
    transition: top 0.5s;
  }
  .after-arrow-active {
    transition: top 0.5s;
    top: 647px;
    background-color: rgba(0, 166, 113, 0.9);
  }

  .scheme__list--hide .list:first-child {
    padding-bottom: 0;
  }
  .scheme__list--hide .list:last-of-type {
    padding-top: 0;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .scheme__info {
    padding-top: 20px !important;
  }
  .scheme__list--hide {
    height: 205px;
  }
  .scheme__list--hide-active {
    height: 625px;
  }
  .scheme, .list {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .list {
    padding: 5px 10px;
  }

  .list.neighbourhood__list {
    padding: 5px 10px;
  }
  .scheme__link {
    margin-top: 20px;
  }
  .scheme__list {
    display: none;
  }
  .scheme__list--mobile {
    margin-top: 30px;
    display: block;
  }
  .scheme__list--hide {
    position: static;
  }
  .after-arrow {
    position: static;
    width: 100%;
  }
  .after-arrow-active {
    background-color: rgba(0, 166, 113, 1);
  }
  .scheme__list--hide-active .list {
    background-color: rgba(0, 166, 113, 1);
  }
}
