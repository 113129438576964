.investment.park.contact {
  background-image: url(../images/bg-kontakt.jpg);
  text-align: left;
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  a {
    color: $font2;
  }
}

.contact__form input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.contact__logo a:hover {
  color: $zielony !important;
  transition: all 0.3s;
}

.contact__logo {
  padding-top: 30px;
  img {
    max-width: 240px !important;
  }
  a {
    display: block;
    padding-bottom: 30px;
    transition: all 0.3s;
  }
}

.error {
  color: red;
}
.contact__data {
  > a {
    display: block;
    padding-bottom: 30px;
    transition: all 0.3s;
  }
  .contact__text {
    padding-bottom: 30px;
  }
}
.contact__data a:hover {
  color: $zielony !important;
  transition: all 0.3s;
}
.contact__data a.nip {
  color: #fff !important;
}
.contact__data a.nip:hover {
  color: #fff !important;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.form__error {
  width: 100%;
}
.contact__form {
  display: flex;
  flex-direction: column;
  input, textarea {
    border: none;
    border-radius: 0;
    appearance: none;
    width: 100%;
    margin-bottom: 25px;
    font-size: 16px;
    font-family: $Lato-Regular;
    padding: 10px;
    color: $font1;
  }
  input:placeholder, textarea:placeholder {
    opacity: 1;
    font-size: 16px;
    font-family: $Lato-Regular;
    color: $font1;
  }
  input[type="submit"] {
    display: block;
    padding: 0;
    line-height: 16px;
    background-color: $szary-ciemny;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #4b4c51;
      transition: all 0.3s;
    }
  }
  textarea {
    height: 215px;
    resize: none;
  }
  .form__password--wrapper {
    width: 100%;
    display: flex;
    input {
      width: 50%;
    }
  }
  .contact__confirm {
    display: flex;
    flex-wrap: wrap;
    > input {
      width: 50%;
    }
  }
  .div {
    width: 50%;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $Lato-Bold;
    color: $black;
  }
  input, .div {
    height: 35px;
    background-color: $white;
  }
}

.form__roll {
  padding: 10px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-image: url(../images/arrow-button-down.svg);
  background-position: center right 10px;
  background-color: $zielony;
  background-repeat: no-repeat;
  display: none;
}
.form__roll--active {
  background-image: url(../images/arrow-button-up.svg);
}
.wizualni {
  color: #fff;
}
.wizualni:hover {
  transition: all 0.3s;
  color: $zielony;
}
@media screen and (max-width: 1024px) {
  .contact__data a {
    color: $zielony !important;
  }
  .wizualni {
    color: $zielony !important;
  }
  .contact__logo a {
    color: $zielony !important;
    transition: all 0.3s;
  }
}
@media screen and (max-width: 991px) {
  .form__roll {
    display: flex;
  }
}
.contact__confirm--input {
}

.footer {
  width: 100%;
  height: 70px;
  background-color: #252525;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: $Lato-Regular;
  color: #e5e5e5;
}

.footer-bootsrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-bootsrap br {
  display: none;
}
@media screen and (max-width: 991px) {
  .footer-bootsrap {
    flex-wrap: wrap;
  }
  .footer-bootsrap p {
    width: 100%;
    line-height: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: auto;
    padding: 15px 5px;
  }
  .footer-bootsrap p {
    span {
      display: none;
    }
  }
  .footer-bootsrap br {
    display: block;
  }
}


.full__site {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $font2;
  font-size: 2.3rem;
  font-family: $Lato-Bold;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  background-color: $brazowy;
  height: 90px;
  background-image: url(../images/arrow-pelna-wersja-strony.svg);
  background-repeat: no-repeat;
  background-position: center right 23px;
  padding: 0 50px;
  text-transform: uppercase;
  display: none;
}
@media screen and (max-width: 991px) {
  .full__site {
    display: flex;
  }
}
