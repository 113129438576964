.rail {
  background-image: url(../images/bg-kolej.jpg);
  background-size: cover;
  color: $font2;
  padding-bottom: 60px;
}

.rail__map.map {
  margin-top: 30px;
}

.row-reset {
  margin-left: 0;
  margin-right: 0;
}
.neighbourhood__info.scheme__info {
  padding-top: 30px;
}
.neighbourhood__element.list__element {
  display: flex;
  align-items: center;
  line-height: 2rem;
  padding: 5px 0;
  transition: all 0.3s;
}
.neighbourhood__element .neighbourhood__image path{
  transition: all 0.3s;
}
.neighbourhood__list {
  cursor: pointer;
}
.neighbourhood__element.neighbourhood__active {
  color: #60372f;
}
.neighbourhood__element.neighbourhood__active .neighbourhood__image path{
  fill: #60372f;
}
.neighbourhood__element:hover {
  color: #60372f;
  transition: all 0.3s;
}
.neighbourhood__element:hover .neighbourhood__image path {
  fill: #60372f;
  transition: all 0.3s;
}


.neighbourhood__image {
  margin-right: 10px;
}

.investment.park {
  background-image: url(../images/bg-park.jpg);
  padding-bottom: 60px;
}

.park__image {
  img {
    width: 100%;
    height: auto;
  }
}
.park__info {
  padding-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .neighbourhood__element:hover {
    color: #fff;
  }
}
@media screen and (max-width: 991px) {
  .park__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .neighbourhood__element.neighbourhood__active {
    color: #fff;
  }
  .neighbourhood__element.neighbourhood__active .neighbourhood__image path{
    fill: #fff;
  }
  .neighbourhood__element:hover {
    color: #fff;
  }
  .neighbourhood__element:hover .neighbourhood__image path {
    fill: #fff;
  }
  .rail__map.map {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .park__info--first {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .investment.park {
    background-image: url(../images/bg-park-mob.jpg);
  }
}


@media screen and (max-width: 500px) {
  .rail {
    background-image: url(../images/bg-kolej-mob.jpg);
  }
}
