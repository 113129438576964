.investment.park.standard {
  background-image: url(../images/bg-standard.jpg);
}

.standard__title {
  background-color: $zielony;
  text-transform: uppercase;
  text-align: center;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.standard__box {
  margin-bottom: 30px;
}
.standard__list {
  color: $font1;
  background-color: $szary4;
  font-size: 15px;
  padding: 10px 20px;
  padding-left: 15px;
  li {
    padding-left: 20px;
    background-image: url(../images/line.svg);
    background-repeat: no-repeat;
    background-position: left top 12px;
  }
}

.roll-down.scheme__link {
  margin-top: 20px;
  height: 35px;
  background-color: $zielony !important;
  display: flex;
  display: none;
  align-items: center;
  background-image: url(../images/arrow-button-down.svg);
}
.roll-down.roll-down--active.scheme__link {
  background-color: $zielony;
  background-image: url(../images/arrow-button-up.svg);
}

@media screen and (max-width: 991px) {
  .roll-down.scheme__link {
    display: flex;
  }
  .standard__row {
    //height: 690px;
    overflow: hidden;
    transition: all 0.5s;
  }
  .standard__row--active {
    height: auto;
    transition: all 0.5s;
  }
  .roll-down.scheme__link {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .standard__row {
    //height: 650px;
  }
  .standard__row--active {
    height: auto;
    transition: all 0.5s;
  }
  .investment.park.standard {
    padding-left: 8px;
    padding-right: 8px;
  }
  .roll-down.scheme__link {
    margin-left: -15px;
    margin-right: -15px;
  }
}
