* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato-Bold", sans-serif;
  color:  #54555a;
}
