html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, address,
em, img,
strike, strong,
ol, ul, li,
fieldset, form, label, legend,
article, aside,
figure, figcaption, footer, header,
menu, nav, output, section,
audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, figcaption, figure,
footer, header, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
a {
	text-decoration: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
