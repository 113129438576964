@font-face
{
    font-family: Foco-Regular;
    src: url(../font/Foco-Regular.ttf);
}

@font-face
{
    font-family: Foco-Bold;
    src: url(../font/Foco-Bold.otf);
}


@font-face
{
    font-family: Lato-Regular;
    src: url(../font/Lato-Regular.ttf);
}

@font-face
{
    font-family: Lato-Bold;
    src: url(../font/Lato-Bold.ttf);
}

@font-face
{
    font-family: Panton-BlackCaps;
    src: url(../font/Panton-BlackCaps.otf);
}

@font-face
{
    font-family: Panton-Regular;
    src: url(../font/Panton-Regular.otf);
}

@font-face
{
    font-family: Panton-Black;
    src: url(../font/Panton-Black.otf);
}

@font-face
{
    font-family: Panton-Light;
    src: url(../font/Panton-Light.otf);
}

@font-face
{
    font-family: Panton-LightItalic;
    src: url(../font/Panton-LightItalic.otf);
}

@font-face
{
    font-family: Panton-Bold;
    src: url(../font/Panton-Bold.otf);
}


@font-face
{
    font-family: Panton-ExtraLight;
    src: url(../font/Panton-ExtraLight.otf);
}


@font-face
{
    font-family: Panton-LightCaps;
    src: url(../font/Panton-LightCaps.otf);
}

@font-face
{
    font-family: Panton-LightitalicCaps;
    src: url(../font/Panton-LightitalicCaps.otf);
}

@font-face
{
    font-family: Panton-LightitalicCaps;
    src: url(../font/Panton-ExtraLight.otf);
}

@font-face
{
    font-family: Panton-BoldItalic;
    src: url(../font/Panton-BoldItalic.otf);
}

@font-face
{
    font-family: Panton-SemiBold;
    src: url(../font/Panton-SemiBold.otf);
}

@font-face
{
    font-family: Panton-Thin;
    src: url(../font/Panton-Thin.otf);
}

@font-face
{
    font-family: Panton-BlackitalicCaps;
    src: url(../font/Panton-BlackitalicCaps.otf);
}

@font-face
{
    font-family: Roboto-Black;
    src: url(../font/Roboto-Black.ttf);
}

@font-face
{
    font-family: Roboto-Light;
    src: url(../font/Roboto-Light.ttf);
}

@font-face
{
    font-family: Roboto-MediumItalic;
    src: url(../font/Roboto-MediumItalic.ttf);
}



@font-face
{
    font-family: Proxima-Regular;
    src: url(../font/proximanovasoft-regular-ttf.ttf);
}

@font-face
{
    font-family: Proxima-Bold;
    src: url(../font/proximanovasoft-bold-ttf.ttf);
}
