.flat1.map, .flat2.map {
  margin-top: 0;
  z-index: 2;
  img {
    max-width: 100%;
    height: auto;
  }
}
.flat3 {
  margin-top: -150px;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.flat-mlodych {
  margin-top: 40px;
  display: block;
  width: 70px;
  img {
    display: inline-block;
    width: 70px;
  }
}
.padding0 {
  padding-left: 0;
  padding-right: 0;
}
.flats {
  width: 100%;
  max-width: 1920px;
  background: none;
  color: $font1;
  ////padding-left: 0;
  //padding-right: 0;
  padding-bottom: 0;
  padding-top: 60px;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: justify;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .flats {
    padding-left: 8px;
    padding-right: 8px;
  }
  .flat-flex {
    display: flex;
    div {
      width: 50%;
    }
    div:first-of-type {
      margin-right: 10px;
    }
    div:last-of-type {
      margin-left: 10px;
    }
  }
  .flat3 {
    margin-top: 0;
    margin-left: -8px;
    margin-right: -8px;
  }
  .scheme__link.flat__link, .flat-mlodych {
    margin-top: 20px;
  }
  .flat1.map {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .flat-last.investment__info--text:last-of-type {
    padding-bottom: 0;
  }
  .flats {
    padding-top: 30px;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .flat3 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .flat-flex {
    display: block;
    div {
      width: 100%;
    }
    div:first-of-type {
      margin-right: 0px;
    }
    div:last-of-type {
      margin-left: 0px;
    }
  }
}
