/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.33333%;
  }
  .col-sm-2 {
    width: 16.66667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333%;
  }
  .col-sm-5 {
    width: 41.66667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333%;
  }
  .col-sm-8 {
    width: 66.66667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333%;
  }
  .col-sm-11 {
    width: 91.66667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.33333%;
  }
  .col-sm-push-2 {
    left: 16.66667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.33333%;
  }
  .col-sm-push-5 {
    left: 41.66667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.33333%;
  }
  .col-sm-push-8 {
    left: 66.66667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.33333%;
  }
  .col-sm-push-11 {
    left: 91.66667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.33333%;
  }
  .col-md-pull-2 {
    right: 16.66667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.33333%;
  }
  .col-md-pull-5 {
    right: 41.66667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.33333%;
  }
  .col-md-pull-8 {
    right: 66.66667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.33333%;
  }
  .col-md-pull-11 {
    right: 91.66667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.33333%;
  }
  .col-md-push-2 {
    left: 16.66667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.33333%;
  }
  .col-md-push-5 {
    left: 41.66667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.33333%;
  }
  .col-md-push-8 {
    left: 66.66667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.33333%;
  }
  .col-md-push-11 {
    left: 91.66667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.33333%;
  }
  .col-lg-2 {
    width: 16.66667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333%;
  }
  .col-lg-5 {
    width: 41.66667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333%;
  }
  .col-lg-8 {
    width: 66.66667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333%;
  }
  .col-lg-11 {
    width: 91.66667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.33333%;
  }
  .col-lg-push-2 {
    left: 16.66667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.33333%;
  }
  .col-lg-push-5 {
    left: 41.66667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.33333%;
  }
  .col-lg-push-8 {
    left: 66.66667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.33333%;
  }
  .col-lg-push-11 {
    left: 91.66667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, address,
em, img,
strike, strong,
ol, ul, li,
fieldset, form, label, legend,
article, aside,
figure, figcaption, footer, header,
menu, nav, output, section,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato-Bold", sans-serif;
  color: #54555a;
}

@font-face {
  font-family: Foco-Regular;
  src: url(../font/Foco-Regular.ttf);
}

@font-face {
  font-family: Foco-Bold;
  src: url(../font/Foco-Bold.otf);
}

@font-face {
  font-family: Lato-Regular;
  src: url(../font/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Bold;
  src: url(../font/Lato-Bold.ttf);
}

@font-face {
  font-family: Panton-BlackCaps;
  src: url(../font/Panton-BlackCaps.otf);
}

@font-face {
  font-family: Panton-Regular;
  src: url(../font/Panton-Regular.otf);
}

@font-face {
  font-family: Panton-Black;
  src: url(../font/Panton-Black.otf);
}

@font-face {
  font-family: Panton-Light;
  src: url(../font/Panton-Light.otf);
}

@font-face {
  font-family: Panton-LightItalic;
  src: url(../font/Panton-LightItalic.otf);
}

@font-face {
  font-family: Panton-Bold;
  src: url(../font/Panton-Bold.otf);
}

@font-face {
  font-family: Panton-ExtraLight;
  src: url(../font/Panton-ExtraLight.otf);
}

@font-face {
  font-family: Panton-LightCaps;
  src: url(../font/Panton-LightCaps.otf);
}

@font-face {
  font-family: Panton-LightitalicCaps;
  src: url(../font/Panton-LightitalicCaps.otf);
}

@font-face {
  font-family: Panton-LightitalicCaps;
  src: url(../font/Panton-ExtraLight.otf);
}

@font-face {
  font-family: Panton-BoldItalic;
  src: url(../font/Panton-BoldItalic.otf);
}

@font-face {
  font-family: Panton-SemiBold;
  src: url(../font/Panton-SemiBold.otf);
}

@font-face {
  font-family: Panton-Thin;
  src: url(../font/Panton-Thin.otf);
}

@font-face {
  font-family: Panton-BlackitalicCaps;
  src: url(../font/Panton-BlackitalicCaps.otf);
}

@font-face {
  font-family: Roboto-Black;
  src: url(../font/Roboto-Black.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(../font/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(../font/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: Proxima-Regular;
  src: url(../font/proximanovasoft-regular-ttf.ttf);
}

@font-face {
  font-family: Proxima-Bold;
  src: url(../font/proximanovasoft-bold-ttf.ttf);
}

.header__wrapper {
  width: 100%;
  background-color: #60372f;
  position: fixed;
  height: 82px;
  z-index: 15;
}

.slider-wrapper {
  width: 100%;
  padding-top: 82px;
}

.investment-wrapper {
  width: 100%;
}

.header {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 82px;
  padding: 0 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 15;
}

.header__hamburger {
  display: none;
}

.site-h1 {
  text-indent: -100000px;
  font-size: 0.1px;
  height: 0.1px;
  visibility: hidden;
}

.socials__description p {
  color: #00a671;
}

.nav {
  display: flex;
}

.nav__link {
  color: #fff;
  font-size: 1.25rem;
  padding: 0 12px;
  text-transform: uppercase;
  transition: 0.3s;
  font-family: "Lato-Regular", sans-serif;
}

.nav__link:hover {
  color: #00a671;
  transition: 0.3s;
}

.socials__images a svg {
  max-width: 100%;
  height: auto;
  display: block;
}

.nav__link.active {
  color: #00a671;
}

.socials {
  position: relative;
}

.socials__images {
  display: flex;
  align-items: center;
}

.socials__images a {
  margin: 0 5px;
  display: block;
}

.socials__description {
  color: #fff;
  position: absolute;
  top: 35px;
  display: flex;
  font-size: 1.2rem;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.socials__description p {
  display: none;
}

.socials__icon path {
  fill: #fff;
  transition: all 0.3s;
}

.socials__icon:hover path {
  fill: #00a671;
  transition: all 0.3s;
}

@keyframes hamburger {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 1500px) {
  .header {
    padding: 0 1.5%;
  }
  .nav__link {
    padding: 0 6px;
  }
}

@media screen and (max-width: 1250px) {
  .header {
    padding: 0 1%;
  }
  .nav__link {
    padding: 0 3px;
  }
}

@media screen and (max-width: 1150px) {
  .nav__link.active {
    color: #60372f;
  }
  .header {
    padding: 0 23px;
  }
  .header__hamburger {
    display: flex;
    cursor: pointer;
  }
  .header__nav, .header__socials {
    display: none;
  }
  .header.header-active .header__hamburger {
    animation: hamburger;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  .header.header-active .header__nav {
    display: block;
    text-align: center;
    position: absolute;
    top: 82px;
    background: #00a671;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px 0;
  }
  .header.header-active .nav__link {
    display: block;
    font-family: "Lato-Regular", sans-serif;
    font-size: 1.6rem;
    padding: 7px 0;
  }
  .header.header-active .header__socials {
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: #00a671;
    width: 100%;
    left: 0;
    right: 0;
    padding-bottom: 15px;
    top: 411px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 0 10px;
  }
  .header__logo img {
    width: 217px;
  }
}

.slider {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.slider__slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-image: url(../images/slide-01.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 75px;
}

.slider__text {
  padding-top: 25px;
  color: #fff;
  font-size: 1.8rem;
  font-family: "Lato-Regular", sans-serif;
}

.slider.swiper-container .swiper-slide {
  height: calc(100vh - 82px);
}

.slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
  height: 50px;
  width: 26px;
  background-size: contain;
  top: calc(93vh - 82px);
}

.slider.swiper-container .swiper-button-prev {
  background-image: url(../images/arrow-slider-left.svg);
  left: 75px;
}

.slider.swiper-container .swiper-button-next {
  background-image: url(../images/arrow-slider-right.svg);
  right: 75px;
}

@media screen and (max-width: 1500px) {
  .slider.swiper-container .swiper-button-prev {
    left: 20px;
  }
  .slider.swiper-container .swiper-button-next {
    right: 20px;
  }
  .slider__slide {
    padding-bottom: 7vh;
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    height: 50px;
    width: 26px;
    background-size: contain;
  }
}

@media screen and (max-width: 1024px) {
  .slider__image {
    display: none;
  }
  .slider__slide {
    padding-bottom: 115px;
  }
  .slider__text {
    font-size: 2.3rem;
  }
  .slider__text {
    display: none;
  }
  .slider.swiper-container .swiper-button-prev {
    background-image: url(../images/arrow-slider-left-mob.svg);
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .slider.swiper-container .swiper-button-next {
    background-image: url(../images/arrow-slider-right-mob.svg);
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    top: 50%;
  }
}

@media screen and (max-width: 500px) {
  .slider__text {
    font-size: 2.2rem;
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    height: 28px;
    width: 14px;
  }
  .slider.swiper-container .swiper-button-prev {
    left: 20px;
  }
  .slider.swiper-container .swiper-button-next {
    right: 20px;
  }
  .slider__slide {
    padding-bottom: 55px;
  }
}

.investment {
  width: 100%;
  max-width: 1920px;
  background-image: url(../images/bg-inwestycja.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 2.6% 70px 2.6%;
  color: #fff;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: justify;
  margin: 0 auto;
}

.investment__banner {
  text-align: center;
  font-size: 2rem;
  font-family: 2.7rem;
}

.investment__banner--text {
  background-color: #00a671;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.investment__info {
  padding-top: 40px;
  padding-bottom: 40px;
}

.investment__info--text {
  padding-bottom: 25px;
}

.investment__info--text:last-of-type {
  padding-bottom: 0;
}

.investment__element {
  height: 415px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 30px;
}

.investment__element img {
  max-width: 100%;
  height: auto;
}

.investment__element:hover a.investment__link {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;
}

.investment__element a.investment__link {
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-right: 0;
  border: 10px solid #00a671;
  background-size: contain;
  background-repeat: no-repeat;
}

.investment__element1 {
  background-image: url(../images/Osiedle-Klonowe-galeria-01-min.jpg);
}

.investment__element2 {
  background-image: url(../images/Osiedle-Klonowe-galeria-02-min.jpg);
}

.investment__element3 {
  background-image: url(../images/Osiedle-Klonowe-galeria-03-min.jpg);
}

.investment__element4 {
  background-image: url(../images/Osiedle-Klonowe-galeria-04-min.jpg);
}

.investment__element5 {
  background-image: url(../images/Osiedle-Klonowe-galeria-05-min.jpg);
}

.investment__element6 {
  background-image: url(../images/Osiedle-Klonowe-galeria-06-min.jpg);
}

.lg-outer .lg-thumb-item {
  border-color: transparent !important;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #00a671 !important;
}

@media screen and (max-width: 1500px) {
  .investment__element {
    height: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .investment__element a.investment__link {
    visibility: visible;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: none;
    transition: none;
    transform: none;
    margin-right: 0;
    border: none;
  }
  .investment__element {
    height: 215px;
  }
}

@media screen and (max-width: 991px) {
  .width100 {
    width: 100%;
  }
  .investment {
    padding: 50px 13px 30px 13px;
  }
  .investment__padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .investment__element {
    margin-bottom: 20px;
  }
  .investment__banner {
    padding-top: 30px;
  }
  .investment__banner--text {
    height: 76px;
  }
  .investment__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 800px) {
  .investment__element {
    height: 250px;
  }
}

@media screen and (max-width: 500px) {
  .investment__info--text:last-of-type {
    padding-bottom: 30px;
  }
  .investment__info {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .investment__info:last-of-type {
    padding: 0;
  }
  .investment__banner--text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .investment__banner {
    padding-top: 20px;
    font-size: 1.7rem;
    line-height: 2rem;
  }
  .investment__element {
    height: 220px;
  }
  .investment [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .investment {
    padding: 35px 8px 30px 8px;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 400px) {
  .investment__element {
    height: 260px;
  }
}

@media screen and (max-width: 350px) {
  .investment__element {
    height: 220px;
  }
}

.localization {
  width: 100%;
  max-width: 1920px;
  padding: 60px 0 0px 0;
  text-align: justify;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 991px) {
  .localization {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 767px) {
  .localization {
    padding: 30px 8px 30px 8px;
  }
}

@media screen and (max-width: 500px) {
  .localization [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .localization {
    padding: 30px 8px 60px 8px;
  }
}

.rail {
  background-image: url(../images/bg-kolej.jpg);
  background-size: cover;
  color: #fff;
  padding-bottom: 60px;
}

.rail__map.map {
  margin-top: 30px;
}

.row-reset {
  margin-left: 0;
  margin-right: 0;
}

.neighbourhood__info.scheme__info {
  padding-top: 30px;
}

.neighbourhood__element.list__element {
  display: flex;
  align-items: center;
  line-height: 2rem;
  padding: 5px 0;
  transition: all 0.3s;
}

.neighbourhood__element .neighbourhood__image path {
  transition: all 0.3s;
}

.neighbourhood__list {
  cursor: pointer;
}

.neighbourhood__element.neighbourhood__active {
  color: #60372f;
}

.neighbourhood__element.neighbourhood__active .neighbourhood__image path {
  fill: #60372f;
}

.neighbourhood__element:hover {
  color: #60372f;
  transition: all 0.3s;
}

.neighbourhood__element:hover .neighbourhood__image path {
  fill: #60372f;
  transition: all 0.3s;
}

.neighbourhood__image {
  margin-right: 10px;
}

.investment.park {
  background-image: url(../images/bg-park.jpg);
  padding-bottom: 60px;
}

.park__image img {
  width: 100%;
  height: auto;
}

.park__info {
  padding-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .neighbourhood__element:hover {
    color: #fff;
  }
}

@media screen and (max-width: 991px) {
  .park__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .neighbourhood__element.neighbourhood__active {
    color: #fff;
  }
  .neighbourhood__element.neighbourhood__active .neighbourhood__image path {
    fill: #fff;
  }
  .neighbourhood__element:hover {
    color: #fff;
  }
  .neighbourhood__element:hover .neighbourhood__image path {
    fill: #fff;
  }
  .rail__map.map {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .park__info--first {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .investment.park {
    background-image: url(../images/bg-park-mob.jpg);
  }
}

@media screen and (max-width: 500px) {
  .rail {
    background-image: url(../images/bg-kolej-mob.jpg);
  }
}

.flat1.map, .flat2.map {
  margin-top: 0;
  z-index: 2;
}

.flat1.map img, .flat2.map img {
  max-width: 100%;
  height: auto;
}

.flat3 {
  margin-top: -150px;
}

.flat3 img {
  display: block;
  max-width: 100%;
  height: auto;
}

.flat-mlodych {
  margin-top: 40px;
  display: block;
  width: 70px;
}

.flat-mlodych img {
  display: inline-block;
  width: 70px;
}

.padding0 {
  padding-left: 0;
  padding-right: 0;
}

.flats {
  width: 100%;
  max-width: 1920px;
  background: none;
  color: #54555a;
  padding-bottom: 0;
  padding-top: 60px;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: justify;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .flats {
    padding-left: 8px;
    padding-right: 8px;
  }
  .flat-flex {
    display: flex;
  }
  .flat-flex div {
    width: 50%;
  }
  .flat-flex div:first-of-type {
    margin-right: 10px;
  }
  .flat-flex div:last-of-type {
    margin-left: 10px;
  }
  .flat3 {
    margin-top: 0;
    margin-left: -8px;
    margin-right: -8px;
  }
  .scheme__link.flat__link, .flat-mlodych {
    margin-top: 20px;
  }
  .flat1.map {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .flat-last.investment__info--text:last-of-type {
    padding-bottom: 0;
  }
  .flats {
    padding-top: 30px;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .flat3 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .flat-flex {
    display: block;
  }
  .flat-flex div {
    width: 100%;
  }
  .flat-flex div:first-of-type {
    margin-right: 0px;
  }
  .flat-flex div:last-of-type {
    margin-left: 0px;
  }
}

.table {
  padding-top: 60px;
  padding-bottom: 60px;
}

.categories {
  font-size: 1.4rem;
  color: #54555a;
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 0;
  margin-bottom: 5px;
}

.categories__element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories__arrow {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.categories__arrow svg {
  margin: 3px 0;
}

.active polygon {
  fill: #00A671;
}

.content {
  font-size: 1.4rem;
  font-family: "Lato-Regular", sans-serif;
}

.content__element {
  text-align: center;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content__link {
  color: #54555a;
}

.content__status {
  font-family: "Lato-Bold", sans-serif;
  color: #b2b2b2;
}

.content:hover {
  background-color: #00a671;
  color: #fff;
}

.content:hover a {
  color: #fff;
}

.content:hover .content__status {
  color: #fff;
}

.status__free {
  color: #00a671;
}

.status__sold {
  color: #ba2f14;
}

.navigation {
  margin-top: 30px;
  margin-bottom: 30px;
}

.navigation__box {
  display: flex;
  position: relative;
}

.navigation__content {
  z-index: 3;
  padding-left: 10px;
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  width: calc(100% - 35px);
}

.navigation__button {
  z-index: 3;
  background-color: #00a671;
  width: 35px;
  height: 35px;
  background-image: url(../images/arrow-button-down.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.navigation__button:hover {
  background-color: #009565;
}

.navigation__button--active {
  background-image: url(../images/arrow-button-up.svg);
}

.navigation__text {
  font-size: 1.4rem;
  font-family: "Lato-Regular", sans-serif;
}

.navigation__dropdown {
  z-index: 2;
  position: absolute;
  top: 35px;
  left: 15px;
  right: 15px;
  background-color: #e5e5e5;
}

.navigation__filter {
  padding-left: 30px;
  padding-right: 0;
}

.navigation__filter--button, .navigation__filter--button--delete {
  cursor: pointer;
  background-color: #54555a;
  display: block;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.4rem;
}

.navigation__filter--button:hover, .navigation__filter--button--delete:hover {
  background-color: #4b4c51;
}

.navigation__filter--button--delete {
  background-color: #b2b2b2;
  transition: all 0.3s;
}

.navigation__filter--button--delete:hover {
  background-color: #00a671;
  transition: all 0.3s;
}

.navigation__dropdown {
  padding-top: 10px;
}

.navigation__dropdown input[type="radio"] {
  display: none;
}

.navigation__dropdown input[type="radio"] + label {
  color: #54555a;
  font-size: 1.4rem;
  font-family: "Lato-Regular", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  padding-bottom: 10px;
}

.navigation__dropdown input[type="radio"] + label span {
  display: block;
  width: 23px;
  min-width: 23px;
  height: 23px;
  vertical-align: middle;
  background: url(../images/tick.svg) center no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 10px;
}

.navigation__dropdown input[type="radio"]:checked + label span {
  background: url(../images/tick-active.svg) center no-repeat;
  background-size: contain;
}

.pagination {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.pagination__element {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  background-color: #e5e5e5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-family: #54555a;
}

.pagination__element--active {
  background-color: #00a671;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .active polygon {
    fill: #b2b2b2;
  }
  .content:hover {
    background: none !important;
    color: #54555a;
  }
  .content:hover a {
    color: #54555a;
  }
  .content:hover .content__status {
    color: #b2b2b2;
  }
  .content:hover .status__free {
    color: #00a671;
  }
  .content:hover .status__sold {
    color: #ba2f14;
  }
  .navigation__button:hover {
    background-color: #00a671 !important;
  }
  .navigation__filter--button:hover {
    background-color: #54555a !important;
  }
  .navigation__filter--button--delete:hover {
    background-color: #b2b2b2 !important;
  }
}

@media screen and (max-width: 991px) {
  .navigation__filter {
    padding-left: 0px;
    margin-top: 20px;
  }
  .categories__element {
    padding-left: 0;
    padding-right: 0;
  }
  .content__text {
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .navigation__filter .col-xs-6:nth-child(odd) {
    padding-right: 4px;
  }
  .navigation__filter .col-xs-6:nth-child(even) {
    padding-left: 4px;
  }
  .categories__text {
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  .navigation__box:nth-child(odd) {
    padding-right: 4px;
  }
  .navigation__box:nth-child(odd) .navigation__dropdown {
    right: 4px;
    z-index: 50;
  }
  .navigation__box:nth-child(even) {
    padding-left: 4px;
  }
  .navigation__box:nth-child(even) .navigation__dropdown {
    left: 4px;
    z-index: 50;
  }
  .navigation__box:nth-child(1), .navigation__box:nth-child(2) {
    margin-bottom: 20px;
  }
  .table {
    overflow-x: hidden;
    width: 100%;
  }
  .table .padding0 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .table-wrapper {
    overflow: hidden;
  }
  .categories__element {
    flex-direction: column;
  }
  .content.row, .categories.row {
    display: flex;
    width: auto;
    overflow: hidden;
    position: relative;
  }
  .content.row:after {
    content: "";
    width: 50px;
    height: 35px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  .content.row .row, .categories.row .row {
    display: flex;
    position: relative;
  }
  .content.row .col-xs-8, .content.row .col-xs-4, .categories.row .col-xs-8, .categories.row .col-xs-4 {
    width: auto;
  }
  .slide-buttons {
    width: 42px;
    display: flex;
    flex-direction: column;
    z-index: 9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  .slide__button {
    width: 35px;
    height: 35px;
  }
  .slide__button:first-child {
    width: 35px;
    height: 35px;
    background-color: #00a671;
    background-image: url(../images/arrow-button-right.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .slide__button:last-child {
    width: 35px;
    height: 35px;
    background-color: #00a671;
    background-image: url(../images/arrow-button-left.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .categories__budynek {
    position: relative;
    z-index: 9;
    background-color: #fff;
    padding-left: 5px;
    padding-right: 0px;
    margin-left: 1px;
    font-size: 13px;
  }
  .categories__slide {
    transition: 0.5s;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
  }
  .content.row .categories__slide {
    white-space: nowrap;
  }
  .content:hover {
    background-color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .table {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.flex {
  display: flex;
}

.projection-wrapper {
  background-image: url(../images/bg-rzuty.jpg);
  width: 100%;
  padding: 100px 0;
}

.projection__map.map {
  margin-top: 0px;
}

.p-nav-element--active {
  background-color: #00a671 !important;
}

.p-nav__container {
  display: flex;
  justify-content: space-between;
}

.p-nav-parter-left, .p-nav-pietro-left {
  margin-right: 30px;
}

.p-nav__container1 {
  margin-bottom: 30px;
}

.p-nav-element {
  cursor: pointer;
  font-size: 1.7rem;
  background-color: #54555a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  transition: all 0.3s;
}

.p-nav-element:hover {
  background-color: #3B3C3F;
  transition: all 0.3s;
}

.projection__data {
  background-color: #57342c;
  padding: 30px 30px;
  color: #fff;
  font-size: 1.7rem;
}

.projection__data--element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.projection__data--element-number {
  display: flex;
  align-items: center;
}

.projection__data--element-number img {
  margin-right: 15px;
  height: 30px;
  width: 30px;
}

.projection__data--element:first-child {
  padding-bottom: 20px;
}

.projection-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projection__data--element-meters {
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .projection {
    width: 100%;
  }
  .projection__js {
    margin: 30px 0;
  }
  .projection-right {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-nav-element, .projection__data {
    font-size: 1.5rem;
  }
  .projection__data {
    padding: 20px 20px;
  }
  .projection-right {
    padding-left: 8px;
    padding-right: 8px;
  }
  .projection-wrapper {
    padding: 60px 0;
  }
  .p-nav-parter-left, .p-nav-pietro-left {
    margin-right: 10px;
  }
  .p-nav__container1 {
    margin-bottom: 10px;
  }
}

.inside-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  background-image: url(../images/bg-wnetrza.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.inside {
  width: calc(100% - calc(calc(100% - 1170px)/2));
  display: flex;
  font-size: 1.7rem;
  line-height: 2.4rem;
}

.inside__title {
  padding-bottom: 20px;
}

.inside__text {
  padding-top: 30px;
  text-align: justify;
}

.inside__content {
  width: 25%;
  margin-right: 15px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inside__background {
  margin-left: 15px;
  width: 75%;
  height: 775px;
  background-image: url(../images/wnetrza-zdjecie.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.inside__link.scheme__link {
  margin-top: 40px;
}

.inside__image-title {
  padding-top: 20px;
  padding-bottom: 40px;
}

.inside__image {
  display: block;
}

.inside__image img {
  max-width: 70%;
  height: auto;
}

.inside__image-title {
  font-size: 1.5rem;
  font-family: "Lato-Regular", sans-serif;
}

@media screen and (max-width: 1500px) {
  .inside__background {
    min-height: 635px;
    height: auto;
  }
  .inside__content {
    width: 30%;
  }
  .inside__background {
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .inside {
    width: 100%;
    padding-left: 23px;
  }
}

@media screen and (max-width: 991px) {
  .inside {
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: column;
  }
  .inside__content {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .inside__content1 {
    width: 50%;
    margin-right: 10px;
  }
  .inside__content2 {
    width: 50%;
    margin-left: 10px;
    padding-top: 60px;
  }
  .inside__text {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .inside__background {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .inside__content3 {
    width: 100%;
  }
  .inside__content {
    flex-wrap: wrap;
  }
  .inside__content1 {
    width: 100%;
    margin-right: 0px;
  }
  .inside__content2 {
    width: 100%;
    margin-left: 0;
  }
  .inside {
    font-size: 1.5rem;
    line-height: 2.2rem;
    padding-left: 8px;
    padding-right: 8px;
  }
  .inside__text {
    padding-bottom: 15px;
    padding-top: 0;
  }
  .inside__background {
    height: 220px;
    min-height: auto;
  }
  .inside__content {
    padding-top: 50px;
  }
  .inside-container {
    padding-bottom: 50px;
  }
  .inside__content2 {
    padding-top: 0px;
  }
  .inside__image-title {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .inside__link.scheme__link {
    margin-top: 20px;
  }
}

.investment.park.standard {
  background-image: url(../images/bg-standard.jpg);
}

.standard__title {
  background-color: #00a671;
  text-transform: uppercase;
  text-align: center;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.standard__box {
  margin-bottom: 30px;
}

.standard__list {
  color: #54555a;
  background-color: #e5e5e5;
  font-size: 15px;
  padding: 10px 20px;
  padding-left: 15px;
}

.standard__list li {
  padding-left: 20px;
  background-image: url(../images/line.svg);
  background-repeat: no-repeat;
  background-position: left top 12px;
}

.roll-down.scheme__link {
  margin-top: 20px;
  height: 35px;
  background-color: #00a671 !important;
  display: flex;
  display: none;
  align-items: center;
  background-image: url(../images/arrow-button-down.svg);
}

.roll-down.roll-down--active.scheme__link {
  background-color: #00a671;
  background-image: url(../images/arrow-button-up.svg);
}

@media screen and (max-width: 991px) {
  .roll-down.scheme__link {
    display: flex;
  }
  .standard__row {
    overflow: hidden;
    transition: all 0.5s;
  }
  .standard__row--active {
    height: auto;
    transition: all 0.5s;
  }
  .roll-down.scheme__link {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .standard__row--active {
    height: auto;
    transition: all 0.5s;
  }
  .investment.park.standard {
    padding-left: 8px;
    padding-right: 8px;
  }
  .roll-down.scheme__link {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.investment.park.inwestor {
  background-image: url(../images/bg-inwestor.jpg);
  color: #54555a;
}

.inwestor__text {
  padding-bottom: 30px;
}

.inwestor__logo {
  display: block;
  padding-bottom: 30px;
}

.none {
  display: none;
}

.inwestor__image {
  padding: 20px;
  display: block;
  height: 270px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}

.inwestor__lightbox {
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
}

.inwestor__lightbox--gradient {
  position: absolute;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 58%, rgba(0, 0, 0, 0.7) 100%);
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
}

.inwestor__lightbox--description {
  z-index: 2;
}

.inwestor__image1 {
  background-image: url(../images/Kamienica-Reymonta-01.jpg);
}

.inwestor__image2 {
  background-image: url(../images/Osiedle-Debowe-01.jpg);
}

.inwestor__image4 {
  background-image: url(../images/Osiedle-Klonowe-blizniaki-01.jpg);
}

.inwestor__image3 {
  background-image: url(../images/Osiedle-Klonowe-domy-01.jpg);
}

.inwestor__image5 {
  background-image: url(../images/Osiedle-Klonowe-mieszkania-02.jpg);
}

@media screen and (max-width: 500px) {
  .inwestor__lightbox {
    margin-bottom: 10px;
  }
  .inwestor__text {
    padding-top: 0;
    padding-bottom: 20px;
  }
  .inwestor__info.park__info {
    padding-top: 0;
  }
  .inwestor__lightbox--gradient {
    left: 0px;
    right: 0px;
  }
}

.investment.park.contact {
  background-image: url(../images/bg-kontakt.jpg);
  text-align: left;
}

.investment.park.contact img {
  max-width: 100%;
  height: auto;
  display: block;
}

.investment.park.contact a {
  color: #fff;
}

.contact__form input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.contact__logo a:hover {
  color: #00a671 !important;
  transition: all 0.3s;
}

.contact__logo {
  padding-top: 30px;
}

.contact__logo img {
  max-width: 240px !important;
}

.contact__logo a {
  display: block;
  padding-bottom: 30px;
  transition: all 0.3s;
}

.error {
  color: red;
}

.contact__data > a {
  display: block;
  padding-bottom: 30px;
  transition: all 0.3s;
}

.contact__data .contact__text {
  padding-bottom: 30px;
}

.contact__data a:hover {
  color: #00a671 !important;
  transition: all 0.3s;
}

.contact__data a.nip {
  color: #fff !important;
}

.contact__data a.nip:hover {
  color: #fff !important;
}

.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.form__error {
  width: 100%;
}

.contact__form {
  display: flex;
  flex-direction: column;
}

.contact__form input, .contact__form textarea {
  border: none;
  border-radius: 0;
  appearance: none;
  width: 100%;
  margin-bottom: 25px;
  font-size: 16px;
  font-family: "Lato-Regular", sans-serif;
  padding: 10px;
  color: #54555a;
}

.contact__form input:placeholder, .contact__form textarea:placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Lato-Regular", sans-serif;
  color: #54555a;
}

.contact__form input[type="submit"] {
  display: block;
  padding: 0;
  line-height: 16px;
  background-color: #54555a;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.contact__form input[type="submit"]:hover {
  background-color: #4b4c51;
  transition: all 0.3s;
}

.contact__form textarea {
  height: 215px;
  resize: none;
}

.contact__form .form__password--wrapper {
  width: 100%;
  display: flex;
}

.contact__form .form__password--wrapper input {
  width: 50%;
}

.contact__form .contact__confirm {
  display: flex;
  flex-wrap: wrap;
}

.contact__form .contact__confirm > input {
  width: 50%;
}

.contact__form .div {
  width: 50%;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato-Bold", sans-serif;
  color: #000;
}

.contact__form input, .contact__form .div {
  height: 35px;
  background-color: #fff;
}

.form__roll {
  padding: 10px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-image: url(../images/arrow-button-down.svg);
  background-position: center right 10px;
  background-color: #00a671;
  background-repeat: no-repeat;
  display: none;
}

.form__roll--active {
  background-image: url(../images/arrow-button-up.svg);
}

.wizualni {
  color: #fff;
}

.wizualni:hover {
  transition: all 0.3s;
  color: #00a671;
}

@media screen and (max-width: 1024px) {
  .contact__data a {
    color: #00a671 !important;
  }
  .wizualni {
    color: #00a671 !important;
  }
  .contact__logo a {
    color: #00a671 !important;
    transition: all 0.3s;
  }
}

@media screen and (max-width: 991px) {
  .form__roll {
    display: flex;
  }
}

.footer {
  width: 100%;
  height: 70px;
  background-color: #252525;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: "Lato-Regular", sans-serif;
  color: #e5e5e5;
}

.footer-bootsrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-bootsrap br {
  display: none;
}

@media screen and (max-width: 991px) {
  .footer-bootsrap {
    flex-wrap: wrap;
  }
  .footer-bootsrap p {
    width: 100%;
    line-height: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: auto;
    padding: 15px 5px;
  }
  .footer-bootsrap p span {
    display: none;
  }
  .footer-bootsrap br {
    display: block;
  }
}

.full__site {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2.3rem;
  font-family: "Lato-Bold", sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  background-color: #60372f;
  height: 90px;
  background-image: url(../images/arrow-pelna-wersja-strony.svg);
  background-repeat: no-repeat;
  background-position: center right 23px;
  padding: 0 50px;
  text-transform: uppercase;
  display: none;
}

@media screen and (max-width: 991px) {
  .full__site {
    display: flex;
  }
}

.title {
  display: flex;
  align-items: flex-end;
}

.title__header {
  border-bottom: 2px solid #00a671;
  color: #fff;
  font-size: 2.4rem;
  display: block;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-left: 20px;
  width: 100%;
  text-align: left;
}

.title__header.main-font {
  color: #54555a;
}

.title__image {
  float: left;
}

@media screen and (max-width: 991px) {
  .title {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .title {
    width: 100%;
  }
}

.scheme {
  font-size: 1.7rem;
  line-height: 2.4rem;
  z-index: 2;
}

.scheme__link {
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  margin-top: 50px;
  color: #fff;
  background-color: #60372f;
  background-image: url(../images/arrow-button-right.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
  text-transform: uppercase;
  transition: 0.3s all;
}

.scheme__link:hover {
  transition: 0.3s all;
  background-color: #56312a;
}

.scheme__info--text {
  padding-bottom: 30px;
}

.scheme__info--text:last-of-type {
  padding-bottom: 0px;
}

#standard .park__info--text {
  text-align: left;
}

#okolica .scheme__info--text.localization__info--text {
  text-align: left;
}

.list {
  font-size: 1.7rem;
  line-height: 2.4rem;
  background-color: #00a671;
  color: #fff;
  padding: 25px 30px;
}

.list__element {
  line-height: 3.2rem;
}

.scheme__list--mobile {
  display: none;
}

.after-arrow {
  display: none;
  width: 100%;
  height: 40px;
  background-color: #00a671;
  background-image: url(../images/arrow-button-down.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.after-arrow-active {
  background-image: url(../images/arrow-button-up.svg);
}

@media screen and (max-width: 991px) {
  .after-arrow {
    display: block;
  }
  .scheme__info {
    padding-top: 30px;
  }
  .scheme__list--hide {
    height: 274px;
    overflow: hidden;
    transition: all 0.5s;
  }
  .scheme__list--hide-active {
    height: 662px;
  }
  .scheme__list--hide-active .list {
    background-color: rgba(0, 166, 113, 0.9);
  }
  .scheme__list--hide {
    position: absolute;
    right: 15px;
    z-index: 11;
  }
  .after-arrow {
    position: absolute;
    top: 274px;
    left: 0;
    width: calc(100% - 15px);
    right: 0;
    transition: top 0.5s;
  }
  .after-arrow-active {
    transition: top 0.5s;
    top: 647px;
    background-color: rgba(0, 166, 113, 0.9);
  }
  .scheme__list--hide .list:first-child {
    padding-bottom: 0;
  }
  .scheme__list--hide .list:last-of-type {
    padding-top: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .scheme__info {
    padding-top: 20px !important;
  }
  .scheme__list--hide {
    height: 205px;
  }
  .scheme__list--hide-active {
    height: 625px;
  }
  .scheme, .list {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .list {
    padding: 5px 10px;
  }
  .list.neighbourhood__list {
    padding: 5px 10px;
  }
  .scheme__link {
    margin-top: 20px;
  }
  .scheme__list {
    display: none;
  }
  .scheme__list--mobile {
    margin-top: 30px;
    display: block;
  }
  .scheme__list--hide {
    position: static;
  }
  .after-arrow {
    position: static;
    width: 100%;
  }
  .after-arrow-active {
    background-color: #00a671;
  }
  .scheme__list--hide-active .list {
    background-color: #00a671;
  }
}

.map {
  position: relative;
  margin-top: -100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.map__image {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.map__resize.map__resize6 {
  bottom: 10px;
  top: auto;
}

.map__image4 {
  padding-left: 8px;
  padding-right: 8px;
}

.flat2.map {
  margin-left: -15px;
  margin-right: -15px;
}

.flat2.map .map__resize {
  right: 2px;
}

.map__resize {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.map__resize--plus {
  width: 36px;
  height: 36px;
  background-image: url(../images/+1.svg);
  background-size: contain;
  margin-bottom: 5px;
}

.map__resize--minus {
  width: 36px;
  height: 36px;
  background-image: url(../images/-1.svg);
  background-size: contain;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 0.45rem solid rgba(0, 0, 0, 0.2);
  border-top-color: #000;
  animation: spin 1s infinite linear;
}

@media screen and (max-width: 1024px) {
  .map__resize {
    display: flex;
  }
  .map__image img {
    width: 100%;
    height: auto;
  }
  .map {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .map {
    margin-left: -8px;
    margin-right: -8px;
  }
  .rail__map.map {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .rail__map.map .map__resize {
    right: 2px;
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

#myBtn {
  color: #fff;
  cursor: pointer;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
  position: relative;
}

/* The Close Button */
.close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 6px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
  font-size: 30px;
  font-family: "Lato-Bold", sans-serif;
  height: 80px;
  background-color: #00a671;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Body */
.modal-body {
  padding: 20px;
  font-size: 20px;
  font-family: "Lato-Regular", sans-serif;
}

.modal-body p {
  padding-bottom: 10px;
}

.modal-body p:last-child {
  padding-bottom: 0;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  top: 50%;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  transform: translateY(-50%);
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1500px) {
  .modal-header {
    font-size: 25px;
    height: 60px;
  }
  .modal-body p {
    padding-bottom: 8px;
  }
  .modal-body {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 90%;
  }
}
