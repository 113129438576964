.slider {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: url(../images/slide-01.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 75px;
  }
  &__text {
    padding-top: 25px;
    color: $font2;
    font-size: 1.8rem;
    font-family: $Lato-Regular;
  }
}

.slider.swiper-container .swiper-slide {
  height: calc(100vh - 82px);
}

.slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
  height: 50px;
  width: 26px;
  background-size: contain;
  top: calc(93vh - 82px);
}

.slider.swiper-container .swiper-button-prev {
  background-image: url(../images/arrow-slider-left.svg);
  left: 75px;
}
.slider.swiper-container .swiper-button-next {
  background-image: url(../images/arrow-slider-right.svg);
  right: 75px;
}

@media screen and (max-width: 1500px) {
  .slider.swiper-container .swiper-button-prev {
    left: 20px;
  }
  .slider.swiper-container .swiper-button-next {
    right: 20px;
  }
  .slider__slide {
    padding-bottom: 7vh;
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    height: 50px;
    width: 26px;
    background-size: contain;
  }
}

@media screen and (max-width: 1024px) {
  .slider__image {
    display: none;
  }
  .slider__slide {
    padding-bottom: 115px;
  }
  .slider__text {
    font-size: 2.3rem;
  }
  .slider__text {
    display: none;
  }
  .slider.swiper-container .swiper-button-prev {
    background-image: url(../images/arrow-slider-left-mob.svg);
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .slider.swiper-container .swiper-button-next {
    background-image: url(../images/arrow-slider-right-mob.svg);
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    top: 50%;
  }
}


@media screen and (max-width: 500px) {
  .slider__text {
    font-size: 2.2rem;
  }
  .slider.swiper-container .swiper-button-prev, .slider.swiper-container .swiper-button-next {
    height: 28px;
    width: 14px;
  }
  .slider.swiper-container .swiper-button-prev {
    left: 20px;
  }
  .slider.swiper-container .swiper-button-next {
    right: 20px;
  }
  .slider__slide {
    padding-bottom: 55px;
  }

}
