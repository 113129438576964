.map {
  position: relative;
  margin-top: -100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &__image {
    margin: 0 auto;
    text-align: center;
    position: relative;
  }
}
.map__resize.map__resize6 {
  bottom: 10px;
  top: auto;
}
.map__image4 {
  padding-left: 8px;
  padding-right: 8px;
}
.flat2.map{
  margin-left: -15px;
  margin-right: -15px;
  .map__resize {
    right: 2px;
  }
}
.map__resize {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  &--plus {
    width: 36px;
    height: 36px;
    background-image: url(../images/+1.svg);
    background-size: contain;
    margin-bottom: 5px;
  }
  &--minus {
    width: 36px;
    height: 36px;
    background-image: url(../images/-1.svg);
    background-size: contain;
  }
}

/// SPIN ///////////////////////////

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

$base-line-height: 70px;
$white: rgb(255,255,255);
$off-white: rgba($black, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

.loading {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .45rem solid $off-white;
    border-top-color: $black;
    animation: spin $spin-duration infinite linear;
}

////////////////////////////////////
@media screen and (max-width: 1024px) {
  .map__resize {
    display: flex;
  }
  .map__image img {
    width: 100%;
    height: auto;
  }
  .map {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .map {
    margin-left: -8px;
    margin-right: -8px;
  }
  .rail__map.map {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    .map__resize {
      right: 2px;
    }
  }
}
