.investment.park.inwestor {
  background-image: url(../images/bg-inwestor.jpg);
  color: $font1;
}
.inwestor__text {
  padding-bottom: 30px;
}

.inwestor__logo {
  display: block;
  padding-bottom: 30px;
}
.none {
  display: none;
}
.inwestor__image {
  padding: 20px;
  display: block;
  height: 270px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-transform: uppercase;
  color: $font2;
  text-align: center;
}

.inwestor__lightbox {
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  //background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 58%,rgba(84,85,90,0.8) 100%);
}
.inwestor__lightbox--gradient {
  position: absolute;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 58%,rgba(0,0,0,0.7) 100%);
  left:15px;
  right: 15px;
  top: 0;
  bottom: 0;
}
.inwestor__lightbox--description {
  z-index: 2;
}

.inwestor__image1 {
  background-image: url(../images/Kamienica-Reymonta-01.jpg);
}

.inwestor__image2 {
  background-image: url(../images/Osiedle-Debowe-01.jpg);
}

.inwestor__image4 {
  background-image: url(../images/Osiedle-Klonowe-blizniaki-01.jpg);
}

.inwestor__image3 {
  background-image: url(../images/Osiedle-Klonowe-domy-01.jpg);
}

.inwestor__image5 {
  background-image: url(../images/Osiedle-Klonowe-mieszkania-02.jpg);
}

@media screen and (max-width: 500px) {
  .inwestor__lightbox {
    margin-bottom: 10px;
  }
  .inwestor__text {
    padding-top: 0;
    padding-bottom: 20px;
  }
  .inwestor__info.park__info {
    padding-top: 0;
  }
  .inwestor__lightbox--gradient {
    left:0px;
    right: 0px;;
  }
}
