.localization {
  width: 100%;
  max-width: 1920px;
  padding: 60px 0 0px 0;
  text-align: justify;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}



@media screen and (max-width: 991px) {
  .localization {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (max-width: 767px) {
  .localization {
    padding: 30px 8px 30px 8px;
  }
}
@media screen and (max-width: 500px) {
  .localization [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .localization {
    padding: 30px 8px 60px 8px;
  }
}
