.table {
  padding-top: 60px;
  padding-bottom: 60px;
}

.categories {
  font-size: 1.4rem;
  color: $szary-ciemny;
  text-align: center;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: 20px 0;
  margin-bottom: 5px;
  &__element {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    svg {
      margin: 3px 0;
    }
  }
}

.active polygon {
  fill: #00A671;
}

.content {
  font-size: 1.4rem;
  font-family: $Lato-Regular;
  &__element {
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__link {
    color: $szary-ciemny;
  }
  &__status {
    font-family: $Lato-Bold;
    color: #b2b2b2;
  }
}

.content:hover {
  background-color: $zielony;
  color: #fff;
  a {
    color: #fff;
  }
  .content__status {
    color: #fff;
  }
}

.status__free {
  color: $zielony;
}
.status__sold {
  color: #ba2f14;
}


.navigation {
  margin-top: 30px;
  margin-bottom: 30px;
  &__box {
    display: flex;
    position: relative;
  }
  &__content {
    z-index: 3;
    padding-left: 10px;
    display: flex;
    align-items: center;
    background-color: $szary4;
    width: calc(100% - 35px);
  }
  &__button {
    z-index: 3;
    background-color: $zielony;
    width: 35px;
    height: 35px;
    background-image: url(../images/arrow-button-down.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:hover {
      background-color: #009565;
    }
  }
  &__button--active {
    background-image: url(../images/arrow-button-up.svg);
  }
  &__text {
    font-size: 1.4rem;
    font-family: $Lato-Regular;
  }
  &__dropdown {
    z-index: 2;
    position: absolute;
    top: 35px;
    left: 15px;
    right: 15px;
    background-color: $szary4;
  }
}

.navigation__filter {
  padding-left: 30px;
  padding-right: 0;
  &--button, &--button--delete {
    cursor: pointer;
    background-color: $szary-ciemny;
    display: block;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 1.4rem;
    &:hover {
      background-color: #4b4c51;
    }
  }
  &--button--delete {
    background-color: #b2b2b2;
    transition: all 0.3s;
    &:hover {
      background-color: #00a671 ;
      transition: all 0.3s;
    }
  }
}
.navigation__dropdown {
  padding-top: 10px;
  input[type="radio"] {
    display:none;
  }

  input[type="radio"] + label {
    color:$szary-ciemny;
    font-size: 1.4rem;
    font-family: $Lato-Regular;
    display:flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    padding-bottom: 10px;
  }

  input[type="radio"] + label span {
    display:block;
    width:23px;
    min-width: 23px;
    height:23px;
    vertical-align:middle;
    background:url(../images/tick.svg) center no-repeat;
    background-size: contain;
    cursor:pointer;
    margin-right: 10px;
  }

  input[type="radio"]:checked + label span {
      background:url(../images/tick-active.svg) center  no-repeat;
      background-size: contain;
  }
}

.pagination {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}
.pagination__element {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  background-color: $szary4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-family: $font1;
  &--active {
    background-color: $zielony;
    color: $font2;
  }
}

@media screen and (max-width: 1024px) {
  .active polygon {
    fill: rgb(178, 178, 178);
  }
  .content:hover {
    background: none !important;
    color: rgb(84, 85, 90);
    a {
      color: rgb(84, 85, 90);
    }
    .content__status {
      color: #b2b2b2;
    }
    .status__free {
      color: $zielony;
    }
    .status__sold {
      color: #ba2f14;
    }
  }
  .navigation__button:hover {
    background-color: $zielony !important;
  }
  .navigation__filter--button:hover {
    background-color: #54555a !important;
  }
  .navigation__filter--button--delete:hover {
    background-color: #b2b2b2 !important;
  }

}

@media screen and (max-width: 991px) {
  .navigation__filter {
    padding-left: 0px;
    margin-top: 20px;
  }
  .categories__element {
    padding-left: 0;
    padding-right: 0;
  }
  .content__text {
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .navigation__filter .col-xs-6:nth-child(odd) {
    padding-right: 4px;
  }
  .navigation__filter .col-xs-6:nth-child(even) {
    padding-left: 4px;
  }
  .categories__text {
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  .navigation__box:nth-child(odd) {
    padding-right: 4px;
    .navigation__dropdown {
      right: 4px;
      z-index: 50;
    }
  }
  .navigation__box:nth-child(even) {
    padding-left: 4px;
    .navigation__dropdown {
      left: 4px;
      z-index: 50;
    }
  }
  .navigation__box:nth-child(1),  .navigation__box:nth-child(2) {
    margin-bottom: 20px;
  }
  .table {
    //width: 700px;
    overflow-x: hidden;
    width: 100%;
    .padding0 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .table-wrapper {
    //width: 700px;
    overflow: hidden;
  }
  .navigation {
  //  width: 100%;
  }
  .categories__element {
    flex-direction: column;
  }
  .content__element, .categories__element {
    //width: 110px !important;
  }
  .content.row , .categories.row {
    display: flex;
    width: auto;
    overflow: hidden;
    position: relative;
  }
  .content.row:after {
    content: "";
    width: 50px;
    height: 35px;
    background-color: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  .content.row .row, .categories.row .row {
    display: flex;
    position: relative;
   }
  .content.row .col-xs-8 , .content.row .col-xs-4, .categories.row .col-xs-8, .categories.row .col-xs-4 {
    width: auto;
  }
  .slide-buttons {
    width: 42px;
    display: flex;
    flex-direction: column;
    z-index: 9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    }
  .slide__button {
    width: 35px;
    height: 35px;
  }
  .slide__button:first-child {
    width: 35px;
    height: 35px;
    background-color: $zielony;
    background-image: url(../images/arrow-button-right.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .slide__button:last-child {
    width: 35px;
    height: 35px;
    background-color: $zielony;
    background-image: url(../images/arrow-button-left.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .categories__budynek {
    position: relative;
    z-index: 9;
    background-color: $white;
    padding-left: 5px;
    padding-right: 0px;
    margin-left: 1px;
    font-size: 13px;
  }
  .categories__slide {
    transition: 0.5s;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
  }
  .content.row   .categories__slide {
    white-space: nowrap;
  }
  .content:hover {
    background-color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .table {
    padding-left: 8px;
    padding-right: 8px;
  }
}
