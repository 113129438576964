.inside-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  background-image: url(../images/bg-wnetrza.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.inside {
  width:calc(100% - calc(calc(100% - 1170px)/2));
  display: flex;
  font-size: 1.7rem;
  line-height: 2.4rem;
}
.inside__title {
  padding-bottom: 20px;
}
.inside__text {
  padding-top: 30px;
  text-align: justify;
}
.inside__content {
  width: 25%;
  margin-right: 15px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inside__background {
  margin-left: 15px;
  width: 75%;
  height: 775px;
  background-image: url(../images/wnetrza-zdjecie.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.inside__link.scheme__link {
  margin-top: 40px;
}
.inside__image-title {
  padding-top: 20px;
  padding-bottom: 40px;
}

.inside__image {
  display: block;
  img {
    max-width: 70%;
    height: auto;
  }
}
.inside__image-title {
  font-size: 1.5rem;
  font-family: $Lato-Regular;
}
@media screen and (max-width: 1500px) {
  .inside__background {
    min-height: 635px;
    height: auto;
  }
  .inside__content {
    width: 30%;
  }
  .inside__background {
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .inside {
    width: 100%;
    padding-left: 23px;
  }
}

@media screen and (max-width: 991px) {
  .inside {
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: column;
  }
  .inside__content {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .inside__content1 {
    width: 50%;
    margin-right: 10px;
  }
  .inside__content2 {
    width: 50%;
    margin-left: 10px;
    padding-top: 60px;
  }
  .inside__text {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .inside__background {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .inside__content3 {
    width: 100%;
  }
  .inside__content {
    flex-wrap: wrap;
  }
  .inside__content1 {
    width: 100%;
    margin-right: 0px;
  }
  .inside__content2 {
    width: 100%;
    margin-left: 0;
  }
  .inside {
    font-size: 1.5rem;
    line-height: 2.2rem;
    padding-left: 8px;
    padding-right: 8px;
  }
  .inside__text {
    padding-bottom: 15px;
    padding-top: 0;
  }
  .inside__background {
    height: 220px;
    min-height: auto;
  }
  .inside__content {
    padding-top: 50px;
  }
  .inside-container {
    padding-bottom: 50px;
  }
  .inside__content2 {
    padding-top: 0px;
  }
  .inside__image-title {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .inside__link.scheme__link {
    margin-top: 20px;
  }
}
